const BASE_PATHS = {
  // ----------------------------- Auth ----------------------------- //
  // Auth
  auth: '/auth',
  authSignIn: '/auth/sign-in',

  // ----------------------------- Portal ----------------------------- //
  // Portal
  portal: '/portal',

  // Portal User
  portalUserProfile: '/portal/user/profile',
  portalUserActivity: '/portal/user/activity',
  portalUserStatistics: '/portal/user/statistic',

  // Portal Activities
  portalActivities: '/portal/activities',
  portalActivityDetailId: '/portal/activity-detail/:id',
  portalUserActivitiesManagement: '/portal/user-activities/management',
  portalUserActivitiesSaved: '/portal/user-activities/save',

  // Portal Activity Categories
  portalActivityCategories: '/portal/activity-categories',

  // Portal Complaints
  portalComplaints: '/portal/complaints',

  // Portal Instruction
  portalInstructions: '/portal/instructions',

  // ----------------------------- Admin ----------------------------- //
  // Admin
  admin: '/admin',
  adminProfile: '/admin/profile',
  adminStatistics: '/admin/statistics',

  // Admin Activity
  adminActivities: '/admin/activities',
  adminActivityId: '/admin/activity/:id',
  adminActivityIdUpdate: '/admin/activity/:id/update',
  adminActivityCreation: '/admin/activity/creation',

  // Admin Activity Category
  adminActivityCategories: '/admin/activity-categories',
  adminActivityCategoryId: '/admin/activity-category/:id',
  adminActivityCategoryIdUpdate: '/admin/activity-category/:id/update',
  adminActivityCategoryCreation: '/admin/activity-category/creation',

  // Admin System User
  adminUsers: '/admin/users',
  adminUserId: '/admin/user/:id',
  adminUserCreation: '/admin/user/creation'
}

export default BASE_PATHS
