import { BASE_PATHS } from '@src/constants/path'
import { RouteObject } from 'react-router-dom'

// Routes
const adminRoutes: RouteObject = {
  path: BASE_PATHS.admin,
  lazy: async () => {
    const { default: Component } = await import('@src/app/pages/admin')
    return { element: <Component /> }
  },
  children: [
    {
      index: true,
      lazy: async () => {
        const { default: Component } = await import('@src/app/pages/admin/pages/home')
        return { element: <Component /> }
      }
    },
    {
      path: BASE_PATHS.adminStatistics,
      lazy: async () => {
        const { default: Component } = await import('@src/app/pages/admin/pages/home')
        return { element: <Component /> }
      }
    },
    {
      path: BASE_PATHS.adminProfile,
      lazy: async () => {
        const { default: Component } = await import('@src/app/pages/admin/pages/user/profile')
        return { element: <Component /> }
      }
    },
    // ----------------------- System Management -----------------------
    {
      path: BASE_PATHS.adminUsers,
      lazy: async () => {
        const { default: Component } = await import('@src/app/pages/admin/pages/system-management/user/user-list')
        return { element: <Component /> }
      }
    },
    // ----------------------- Activity Management -----------------------
    // -- Activity --
    {
      path: BASE_PATHS.adminActivities,
      lazy: async () => {
        const { default: Component } = await import(
          '@src/app/pages/admin/pages/activity-management/activity/list'
        )
        return { element: <Component /> }
      }
    },
    {
      path: BASE_PATHS.adminActivityCreation,
      lazy: async () => {
        const { default: Component } = await import(
          '@src/app/pages/admin/pages/activity-management/activity/creation'
        )
        return { element: <Component /> }
      }
    },
    {
      path: BASE_PATHS.adminActivityId,
      lazy: async () => {
        const { default: Component } = await import(
          '@src/app/pages/admin/pages/activity-management/activity/detail'
        )
        return { element: <Component /> }
      }
    },
    {
      path: BASE_PATHS.adminActivityIdUpdate,
      lazy: async () => {
        const { default: Component } = await import(
          '@src/app/pages/admin/pages/activity-management/activity/update'
        )
        return { element: <Component /> }
      }
    },

    // -- Activity Category --
    {
      path: BASE_PATHS.adminActivityCategories,
      lazy: async () => {
        const { default: Component } = await import(
          '@src/app/pages/admin/pages/activity-management/activity-category/list'
        )
        return { element: <Component /> }
      }
    },
    {
      path: BASE_PATHS.adminActivityCategoryCreation,
      lazy: async () => {
        const { default: Component } = await import(
          '@src/app/pages/admin/pages/activity-management/activity-category/creation'
        )
        return { element: <Component /> }
      }
    },
    {
      path: BASE_PATHS.adminActivityCategoryId,
      lazy: async () => {
        const { default: Component } = await import(
          '@src/app/pages/admin/pages/activity-management/activity-category/detail'
        )
        return { element: <Component /> }
      }
    },
    {
      path: BASE_PATHS.adminActivityCategoryIdUpdate,
      lazy: async () => {
        const { default: Component } = await import(
          '@src/app/pages/admin/pages/activity-management/activity-category/update'
        )
        return { element: <Component /> }
      }
    },
  ]
}

export default adminRoutes
