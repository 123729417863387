// Core
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

// App
import { useAuthStore } from '@src/stores'
import { useFetchUserInformation } from '@src/lib/hooks'
import { AppLoadingIcon, AppNavigationProgressBarProvider, AppThemeProvider } from '@src/components'

import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";

// Custom Theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#dc004e",
    },
    background: {
      default: "#f4f6f8",
    },
  },
});

// Component
const App = () => {
  const {
    handleFetchUserInformation,
    isError: isFetchUserInformationError,
    isFetching: isFetchingUserInformation
  } = useFetchUserInformation()

  // Stores
  // Client
  const isLoggedIn = useAuthStore(({ isLoggedIn }) => isLoggedIn)

  // Effects
  useEffect(() => {
    console.log("Rebuild")
  }, [])

  // Handle fetch user information on the first mount if user is logged in
  useEffect(() => {
    if (isLoggedIn) {
      handleFetchUserInformation()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Template
  // Fetching
  if (isFetchingUserInformation) {
    return (
      <div className='py-6 flex justify-center items-center h-[100vh]'>
        <AppLoadingIcon />
      </div>
    )
  }

  // Error
  if (isFetchUserInformationError) {
    window.location.href = '/'
    // return <div className='py-6 flex justify-center items-center'>Có lỗi xảy ra, vui lòng tải lại trang.</div>
  }

  // Finally
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppThemeProvider />
      <AppNavigationProgressBarProvider />
      <Outlet />
    </ThemeProvider>
  )
}

export default App
